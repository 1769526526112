import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import preval from 'babel-plugin-preval/macro';
import Header from './Layout/Header/Header';
import Footer from './Layout/Footer/Footer';
import Content from './Layout/Content/Content';
import AppContext from '../context/AppContext';
import IApp from '../interfaces/App';
import apiFetch from '../store/Api';
import AccessDenied from './Pages/AccessDenied/AccessDenied';
import ExitRamp from './Shared/ExitRamp/ExitRamp';
import Login from './Pages/Login/Login';
import Internal from '../store/Internal';
import ServerError from './Pages/ServerError/ServerError';
import congressBg from '../assets/images/bg-home.webp';
import mslBg from '../assets/images/msl-bg.webp';
import {
  getCookie, setCookie, isGAEnabled, removeCookie,
} from '../helpers/Cookies';
import PageLoader from './Shared/PageLoader/PageLoader';
import { isStaticBuildApp, isOfflineApp } from '../helpers/Common';

interface IState {
  data: IApp | null, // Application data fetched from API.
  lastScroll: number, // Last scroll size to detect scroll direction.
  showLogin: boolean, // Show login page or not,
  accessDenied: boolean, // Show/hide access denied page.
  serverError: boolean // Show/hide 500 error page.
  showLoading: boolean,
}

class App extends React.Component<RouteComponentProps, IState> {
  isVeevaPdf = false;

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      data: null,
      lastScroll: 0,
      showLogin: false,
      accessDenied: false,
      serverError: false,
      showLoading: true,
    };
    this.handleWindowScroll = this.handleWindowScroll.bind(this);
    this.handleLoginSuccess = this.handleLoginSuccess.bind(this);
    this.handleClickEvent = this.handleClickEvent.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleWindowScroll);
    const isVeevaPdf = getCookie('isVeeva') === '1';
    if (isVeevaPdf) {
      this.isVeevaPdf = isVeevaPdf;
      this.fetchData(isVeevaPdf);
    } else if (isOfflineApp() || isStaticBuildApp()) {
      const greeting = preval`
      const fs = require('fs')
      module.exports = fs.readFileSync(require.resolve(process.cwd() + '/public/data.json'), 'utf8')`;

      try {
        const res = JSON.parse(greeting);
        this.setState({ showLoading: false });
        if (isOfflineApp()) {
          this.initData(res.data);
        } else {
          // eslint-disable-next-line no-lonely-if
          if (res.data.field_cookie_policy_js_url) {
            this.loadCookiePolicyScript(res.data);
          } else {
            this.initData(res.data);
            if (!res.data.field_cookie_policy_js_url) {
              this.initGA(res.data);
            }
          }
        }
      } catch (e) {
        console.error(e);
        this.setState({ showLoading: false });
      }
    } else {
      fetch('https://ipapi.co/json/').then((res) => res.json()).then((ipResult) => {
        const isVeeva = ipResult.ip === process.env.REACT_APP_VEEVA_IP;
        setCookie('isVeeva', isVeeva ? '1' : '0');
        this.isVeevaPdf = isVeeva;
        this.fetchData(isVeeva);
      }).catch((err) => {
        this.setState({ showLoading: false });
        console.error(err);
      });
    }
  }

  /**
   * Handle window scroll event.
   */
  handleWindowScroll() {
    const header = document.querySelector('.is-sticky-header');
    if (header) {
      const { lastScroll } = this.state;
      const currentScroll = window.pageYOffset;
      if (currentScroll <= 10) {
        header.classList.remove('scroll-up');
        return;
      }
      if (currentScroll > lastScroll && !header.classList.contains('scroll-down')) {
        // down
        header.classList.remove('scroll-up');
        header.classList.add('scroll-down');
      } else if (currentScroll < lastScroll && header.classList.contains('scroll-down')) {
        // up
        header.classList.remove('scroll-down');
        header.classList.add('scroll-up');
      }
      this.setState({
        lastScroll: currentScroll,
      });
    }
  }

  /**
   * Handle success login event.
   *
   * @param app
   */
  handleLoginSuccess(app: IApp) {
    if (app.field_cookie_policy_js_url) {
      this.loadCookiePolicyScript(app);
    } else {
      this.initData(app);
    }
  }

  handleClickEvent(event: Event) {
    const { target } = event;
    const { data } = this.state;
    if (target && data) {
      this.initCookie(data);
    }
  }

  /**
   * Set theme background.
   *
   * @param theme
   */
  static setTheme(theme?: string) {
    const bgColor = '#07383f';
    if (theme === 'congress') {
      document.body.style.backgroundColor = bgColor;
      document.body.style.backgroundImage = `url(${congressBg})`;
    } else if (theme === 'msl') {
      document.body.style.backgroundColor = bgColor;
      document.body.style.backgroundImage = `url(${mslBg})`;
    }
  }

  getGALocationPath = () => {
    let locationPath = window.location.pathname + window.location.search;
    if (isOfflineApp()) {
      locationPath = window.location.hash;
      if (locationPath && locationPath.substr(0, 1) === '#') {
        locationPath = locationPath.substr(1);
      }
    }
    return locationPath;
  };

  isDisableGAUntilCookieAccepted = (data: IApp) => {
    let countryCode = '';
    if (data && data.field_country_iso_code) {
      countryCode = data.field_country_iso_code.toLowerCase();
    }

    if (countryCode === 'de' || countryCode === 'fr' || countryCode === 'ch' || countryCode === '') {
      return true;
    }
    return false;
  };

  fetchData(isVeeva: boolean) {
    apiFetch(Internal.getPassword(), isVeeva).then((result) => {
      this.setState({ showLoading: false });
      if (result.data.data.field_cookie_policy_js_url && !isVeeva) {
        this.loadCookiePolicyScript(result.data.data);
      } else {
        this.initData(result.data.data);
        if (!result.data.data.field_cookie_policy_js_url) {
          this.initGA(result.data.data);
        }
      }
    }).catch((error) => {
      this.setState({ showLoading: false });
      if (error.response && error.response.status === 403) {
        switch (error.response.data.code) {
          case 1:
            this.setState({
              serverError: false,
              accessDenied: false,
              showLogin: true,
            });
            break;
          default:
            this.setState({
              serverError: false,
              accessDenied: true,
              showLogin: false,
            });
        }
      } else {
        this.setState({
          serverError: true,
          accessDenied: false,
          showLogin: false,
        });
      }
      console.error(error);
    });
  }

  /**
   * Initialize App data.
   *
   * @param data
   */
  initData(data: IApp) {
    this.setState({
      data,
      showLogin: false,
      accessDenied: false,
      serverError: false,
    });
    App.setTheme(data.field_theme);

    /* const isDisableGA = this.isDisableGAUntilCookieAccepted(data);
    if (!isDisableGA) {
      this.initGA(data);
    } */
  }

  loadCookiePolicyScript(data: IApp) {
    // const isDisableGA = this.isDisableGAUntilCookieAccepted(data);
    if (data && data.field_cookie_policy_js_url) {
      const script = document.createElement('script');
      script.src = data.field_cookie_policy_js_url;
      script.onload = () => {
        this.initData(data);
        window.addEventListener('click', this.handleClickEvent);
        /* if (isDisableGA) {
          window.addEventListener('click', this.handleClickEvent);
        } */
      };
      document.body.appendChild(script);
    }
  }

  initCookie(data: IApp) {
    const gaEnabled = isGAEnabled();
    const gId = getCookie('_gid');
    if (gaEnabled && gId.length === 0) {
      if (data.field_google_analytics) {
        this.initGA(data);
      }
      window.removeEventListener('click', this.handleClickEvent);
    } else if (!gaEnabled) {
      removeCookie('_ga');
      removeCookie('_gat');
      removeCookie('_gid');
    } else {
      window.removeEventListener('click', this.handleClickEvent);
    }
  }

  initGA(data: IApp) {
    if (data.field_google_analytics) {
      ReactGA.initialize(data.field_google_analytics);
      ReactGA.pageview(this.getGALocationPath());
      const { history } = this.props;
      history.listen(() => {
        setTimeout(() => {
          ReactGA.pageview(this.getGALocationPath());
        });
      });

      const orgReplaceState = window.history.replaceState;
      window.history.replaceState = function (data1: any, title: string, url?: string | null) {
        let locationUrl = url;
        if (isOfflineApp() && locationUrl && locationUrl.substr(0, 1) === '#') {
          locationUrl = locationUrl.substr(1);
        }
        if (locationUrl) {
          ReactGA.pageview(locationUrl);
        }
        orgReplaceState.apply(this, [data1, title, url]);
      };
    }
  }

  render() {
    // const { location } = this.props;
    const {
      data, accessDenied, showLogin, serverError, showLoading,
    } = this.state;

    if (!this.isVeevaPdf && showLoading) {
      return (<PageLoader />);
    }

    if (serverError) {
      return (<ServerError />);
    }

    if (accessDenied) {
      return (<AccessDenied />);
    }

    if (showLogin) {
      return (<Login onSuccess={(app) => this.handleLoginSuccess(app)} />);
    }

    if (data) {
      return (
        <AppContext.Provider value={data}>
          <Header />
          <Content />
          <Footer />
          <ExitRamp />
        </AppContext.Provider>
      );
    }
    return (<div />);
  }
}

export default withRouter(App);
